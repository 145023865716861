import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PublicTrafficService {
  private datasource1 = 'https://2.db.transport.rest/stations/008080660/departures';
  private datasource2 = 'https://easygo.tafmobile.de/easygo2/4.6.0/regions/9/modules/stationmonitor/';

  date: Date;
  departure: Date;
  year = 0;
  month = 0;
  day = 0;
  hour = 0;
  minute = 0;

  dateTimeString: string;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
  ) { }

  createTimeString(delay: number) {
    this.date = new Date();
    this.departure = new Date();
    this.departure.setMinutes(this.date.getMinutes() + delay);

    return this.dateTimeString = this.datePipe.transform(this.departure, 'yyyy-MM-dd HH:mm');
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public getTrafficData(): Observable<any> {
    const options = new HttpParams({
      fromObject: {
        when: 'in 5 minutes',
        duration: '10'
      }
    });
    console.log('Get more trams!');
    return this.http.get<any>(this.datasource1,
      { params: options }).pipe(retry(3), catchError(this.handleError));
  }

  public getMvbTrafficData(delay: number): Observable<any> {
    this.createTimeString(delay);

    const options = new HttpParams({
      fromObject: {
        transportFilter: '111111111',
        hafasID: 'A=1@O=Magdeburg, Leiterstr.@X=11634064@Y=52127664@U=80@L=000007464@B=1@p=1593706228@',
        mode: 'DEP',
        time: this.dateTimeString
      }
    });
    console.log('Get more trams!');
    this.createTimeString(5);
    return this.http.get<any>(this.datasource2,
      { params: options }).pipe(retry(3), catchError(this.handleError));
  }
}
