import { Component, OnInit } from '@angular/core';
import { LocalWeatherService } from '../../_shared/services/local-weather.service';

@Component({
  selector: 'is-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.sass']
})
export class WeatherComponent implements OnInit {
  weather = [];
  weatherIcons = [];
  interval: any;

  constructor(
    private weatherService: LocalWeatherService
  ) { }

  ngOnInit() {
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 300000);
  }

  refreshData() {
    this.getWeather();
  }

  private getWeather() {
    this.weatherService.getWeatherData().subscribe((data) => {
      this.weather = data.main;
      this.weatherIcons = data.weather[0];
      // console.table(this.weather);
    });
  }
}
