import { Component, OnInit } from '@angular/core';
import { PublicTrafficService } from '../../_shared/services/public-traffic.service';

@Component({
  selector: 'is-traffic-mvb',
  templateUrl: './traffic-mvb.component.html',
  styleUrls: ['./traffic-mvb.component.sass']
})
export class TrafficMvbComponent implements OnInit {
  trams = [];
  interval: any;

  constructor(
    private trafficService: PublicTrafficService
  ) { }

  ngOnInit() {
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 30000);
  }

  refreshData() {
    this.getTrams();
  }

  private getTrams() {
    this.trafficService.getMvbTrafficData(5).subscribe((data) => {
      this.trams = data.journeys;
      console.table(this.trams);
    });
  }
}
