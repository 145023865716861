import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable, throwError} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OredCmsService {
  private datasource = 'https://info.ocodes.de:8189/rest/';

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
  ) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public getListofClients(userId: number, rubrik: number): Observable<any> {
    const options = new HttpParams({
      fromObject: {
        user_id: userId.toString(),
        rubrik_id: rubrik.toString()
      }
    });
    console.log('Get more clients in categroy ' + rubrik);
    return this.http.get<any>(this.datasource + 'mitglieder/get_company_name',
      { params: options }).pipe(retry(3), catchError(this.handleError));
  }

  public getNextAppointment(userID: number): Observable<any> {
    const options = new HttpParams({
      fromObject: {
        user_id: userID.toString()
      }
    });
    console.log('Get more clients in categroy ' + userID);
    return this.http.get<any>(this.datasource + 'termin/get_termin',
      { params: options }).pipe(retry(3), catchError(this.handleError));
  }
}
