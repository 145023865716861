import { Component, OnInit } from '@angular/core';
import { OredCmsService } from '../_shared/services/ored-cms.service';
import { Appointments } from '../_shared/interfaces/appointments';

@Component({
  selector: 'is-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.sass']
})
export class CoreComponent implements OnInit {
  appointments: Appointments[] = [];
  interval: any;
  title = 'InfoScreen';
  showModal = false;

  constructor(
    private appointmentService: OredCmsService) { }

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    this.getAppointments();
  }

  private getAppointments() {
    this.appointmentService.getNextAppointment(3).subscribe((data) => {
      this.appointments = Object.values(data.object);
      if ( this.appointments.length > 0 ) { this.showModal = true; }
      console.table(this.appointments);
    });
  }
}
