import { Component, OnInit } from '@angular/core';
import { PublicTrafficService } from '../../_shared/services/public-traffic.service';

@Component({
  selector: 'is-traffic',
  templateUrl: './traffic.component.html',
  styleUrls: ['./traffic.component.sass']
})
export class TrafficComponent implements OnInit {
  trams = [];
  interval: any;

  constructor(
    private trafficService: PublicTrafficService
  ) { }

  ngOnInit() {
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 30000);
  }

  refreshData() {
    this.getTrams();
  }

  private getTrams() {
    this.trafficService.getTrafficData().subscribe((data) => {
      this.trams = data;
      // console.table(this.trams);
    });
  }
}
