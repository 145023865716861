import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreComponent } from './core/core.component';
import { HttpClientModule } from '@angular/common/http';
import { WeatherComponent } from './cards/weather/weather.component';
import { TrafficComponent } from './cards/traffic/traffic.component';
import { ClockComponent } from './cards/clock/clock.component';
import { TrafficMvbComponent } from './cards/traffic-mvb/traffic-mvb.component';
import { DatePipe } from '@angular/common';
import { ClientsComponent } from './cards/clients/clients.component';

@NgModule({
  declarations: [
    AppComponent,
    CoreComponent,
    WeatherComponent,
    TrafficComponent,
    ClockComponent,
    TrafficMvbComponent,
    ClientsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [ DatePipe],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
