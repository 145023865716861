import { Component, Input, OnInit } from '@angular/core';
import { OredCmsService } from '../../_shared/services/ored-cms.service';

@Component({
  selector: 'is-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.sass']
})
export class ClientsComponent implements OnInit {
  @Input() userId: number;
  @Input() clientType: number;
  clients = [];
  interval: any;

  constructor(
    private clientsService: OredCmsService) { }

  ngOnInit() {
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 180000);
  }

  refreshData() {
    this.getClients();
  }

  private getClients() {
    this.clientsService.getListofClients(this.userId, this.clientType).subscribe((data) => {
      this.clients = data.object;
      // console.table(this.trams);
    });
  }
}
