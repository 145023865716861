import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'is-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.sass']
})
export class ClockComponent implements AfterViewInit {

  hourHandStyle;
  minuteHandStyle;
  secondHandStyle;

  /*neddlehours;
  needleminutes;
  needleseconds;
  digital;*/

  isRunning = true;
  timerId: any;

  date: Date;
  hour = 0;
  minute = 0;
  second = 0;

  ngAfterViewInit() {
    this.timerId = this.getTime();
  }

  animateAnalogClock() {
    this.hourHandStyle = { transform: `translate3d(-50%, 0, 0) scale(0.85) rotate(${(this.hour * 30) + (this.minute * 0.5) + (this.second * (0.5 / 60))}deg)` };
    this.minuteHandStyle = { transform: `translate3d(-50%, 0, 0) rotate(${(this.minute * 6) + (this.second * 0.1)}deg)` };
    this.secondHandStyle = { transform: `translate3d(-50%, 0, 0) rotate(${this.second * 6}deg)` };

    /*this.neddlehours = { transform: `rotate(${(this.hour * 30)}deg)` };
    this.needleminutes = { transform: `rotate(${(this.minute * 6)}deg)` };
    this.needleseconds = { transform: `rotate(${(this.second * 6)}deg)` };*/
  }

  getTime() {
    return setInterval(() => {
      this.date = new Date();
      this.hour = this.date.getHours();
      this.minute = this.date.getMinutes();
      this.second = this.date.getSeconds();

      this.animateAnalogClock();
    }, 1000);
  }

  /*horlog() {
    // this.date = new Date();
    this.neddlehours = { transform: `rotate(${(+30 * this.hour)}deg)` };
    this.needleminutes = { transform: `rotate(${(+6 * this.minute)}deg)` };
    this.needleseconds = { transform: `rotate(${(+6 * this.second)}deg)` };
    /!*this.digital").html(this.date.getHours()+" : "+this.date.getMinutes()+" : "+this.date.getSeconds());
    setTimeout(horlog,1000);*!/
  }*/

  format(num: number) {
    return (num + '').length === 1 ? '0' + num : num + '';
  }

  toggle() {
    if (this.isRunning) {
      clearInterval(this.timerId);
    } else { this.getTime(); }

    this.isRunning = !this.isRunning;
  }
}
