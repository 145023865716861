import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalWeatherService {
  private datasource = 'https://api.openweathermap.org/data/2.5/weather';
  private apiKey = '109b82faf96ea08fac7463cf52846b36';

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public getWeatherData(): Observable<any> {
    const options = new HttpParams({
      fromObject: {
        q: 'Magdeburg',
        appid: this.apiKey,
        units: 'metric',
        lang: 'de'
      }
    });
    console.log('Get more weather!');
    return this.http.get<any>(this.datasource,
      { params: options }).pipe(retry(3), catchError(this.handleError));
  }
}
